import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/tmp/node_modules/@nib/gatsby-theme-mesh-docs/src/templates/Content.js";
import { Box, Columns, Column, Stack, Tiles } from '@nib/layout';
import { ApprovalGraphicIcon } from '@nib/icons';
import ImagerySignoff from '../../src/components/imagery-signoff/index.js';
import { FIGMA_LIBRARY_FOUNDATIONS_URL } from '../../src/constants.js';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SecondaryButton = makeShortcode("SecondaryButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`We use a balanced combination of photography and illustration to facilitate humanised, comfortable and confident interaction between our members and nib's digital products and services. `}</p>
    <p>{`For general guidelines on imagery usage within Mesh-enabled experiences, refer to our Foundations documentation. `}</p>
    <SecondaryButton href="/foundations/imagery/" mdxType="SecondaryButton">Imagery foundations</SecondaryButton>
    <ImagerySignoff mdxType="ImagerySignoff" />
    <h2 {...{
      "id": "photography",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#photography",
        "aria-label": "photography permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Photography`}</h2>
    <p>{`Our photography is guided by two sets of principles that capture a spectrum of growth on our members' journey with nib. "The journey to good health" is represented by a sliding scale for what kind of photography we use and the context it's used in. That scale is book-ended by two guiding principles:`}</p>
    <ul>
      <li parentName="ul">{`Your health in your hands - the start of your journey`}</li>
      <li parentName="ul">{`Getting you on your way - when you can live life to the fullest.`}</li>
    </ul>
    <p>{`Not all images need to live at either end of the scale; instead it should guide image selection based on where the prospective member might be on their health journey. `}</p>
    <div>
      <Box background="sageGreen" padding={7} mdxType="Box">
        <Stack space={5} mdxType="Stack">
          <h3 {...{
            "id": "your-health-in-your-hands",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h3" {...{
              "href": "#your-health-in-your-hands",
              "aria-label": "your health in your hands permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "width": "16px",
                "height": "16px",
                "viewBox": "0 0 24 24",
                "version": "1.1",
                "xmlns": "http://www.w3.org/2000/svg",
                "xmlnsXLink": "http://www.w3.org/1999/xlink"
              }}>{`
                `}<g parentName="svg" {...{
                  "id": "Icon/System/Hyperlink",
                  "stroke": "none",
                  "strokeWidth": "1",
                  "fill": "none",
                  "fillRule": "evenodd"
                }}>{`
                    `}<g parentName="g" {...{
                    "id": "bounding-box"
                  }}>{`
                        `}<polygon parentName="g" {...{
                      "id": "shape",
                      "points": "0 0 24 0 24 24 0 24"
                    }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
                    "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
                    "id": "Combined-Shape",
                    "fill": "#444444",
                    "fillRule": "nonzero"
                  }}></path>{`
                `}</g>{`
              `}</svg></a>{`Your health in your hands`}</h3>
          <h4 {...{
            "id": "the-start-of-your-journey",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h4" {...{
              "href": "#the-start-of-your-journey",
              "aria-label": "the start of your journey permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "width": "16px",
                "height": "16px",
                "viewBox": "0 0 24 24",
                "version": "1.1",
                "xmlns": "http://www.w3.org/2000/svg",
                "xmlnsXLink": "http://www.w3.org/1999/xlink"
              }}>{`
                `}<g parentName="svg" {...{
                  "id": "Icon/System/Hyperlink",
                  "stroke": "none",
                  "strokeWidth": "1",
                  "fill": "none",
                  "fillRule": "evenodd"
                }}>{`
                    `}<g parentName="g" {...{
                    "id": "bounding-box"
                  }}>{`
                        `}<polygon parentName="g" {...{
                      "id": "shape",
                      "points": "0 0 24 0 24 24 0 24"
                    }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
                    "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
                    "id": "Combined-Shape",
                    "fill": "#444444",
                    "fillRule": "nonzero"
                  }}></path>{`
                `}</g>{`
              `}</svg></a>{`The start of your journey`}</h4>
          <p>{`The start of your journey should be where we encourage trust and shine a positive light on health. It's optimistic and inviting, and makes good health feel tangible.`}</p>
          <ul>
            <li parentName="ul">{`Authentic optimism, focusing on confidence`}</li>
            <li parentName="ul">{`People actively taking steps towards good health`}</li>
            <li parentName="ul">{`Vulnerability and strength through intimate compositions`}</li>
            <li parentName="ul">{`Portraits or candid moments`}</li>
            <li parentName="ul">{`Relatable and approachable, encouraging trust and assurance`}</li>
            <li parentName="ul">{`Natural and unforced`}</li>
            <li parentName="ul">{`Warm colours and natural lighting`}</li>
          </ul>
        </Stack>
      </Box>
      <p><img parentName="p" {...{
          "src": "/b42c3389f66e435ec78ac895f4d24b5e/healthInHandsCollage.png",
          "alt": "Collage of people smiling"
        }}></img></p>
    </div>
    <div>
      <Box background="warmWhite" padding={7} mdxType="Box">
        <Stack space={5} mdxType="Stack">
          <h3 {...{
            "id": "getting-you-on-your-way",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h3" {...{
              "href": "#getting-you-on-your-way",
              "aria-label": "getting you on your way permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "width": "16px",
                "height": "16px",
                "viewBox": "0 0 24 24",
                "version": "1.1",
                "xmlns": "http://www.w3.org/2000/svg",
                "xmlnsXLink": "http://www.w3.org/1999/xlink"
              }}>{`
                `}<g parentName="svg" {...{
                  "id": "Icon/System/Hyperlink",
                  "stroke": "none",
                  "strokeWidth": "1",
                  "fill": "none",
                  "fillRule": "evenodd"
                }}>{`
                    `}<g parentName="g" {...{
                    "id": "bounding-box"
                  }}>{`
                        `}<polygon parentName="g" {...{
                      "id": "shape",
                      "points": "0 0 24 0 24 24 0 24"
                    }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
                    "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
                    "id": "Combined-Shape",
                    "fill": "#444444",
                    "fillRule": "nonzero"
                  }}></path>{`
                `}</g>{`
              `}</svg></a>{`Getting you on your way`}</h3>
          <h4 {...{
            "id": "when-you-can-live-life-to-the-fullest",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h4" {...{
              "href": "#when-you-can-live-life-to-the-fullest",
              "aria-label": "when you can live life to the fullest permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "width": "16px",
                "height": "16px",
                "viewBox": "0 0 24 24",
                "version": "1.1",
                "xmlns": "http://www.w3.org/2000/svg",
                "xmlnsXLink": "http://www.w3.org/1999/xlink"
              }}>{`
                `}<g parentName="svg" {...{
                  "id": "Icon/System/Hyperlink",
                  "stroke": "none",
                  "strokeWidth": "1",
                  "fill": "none",
                  "fillRule": "evenodd"
                }}>{`
                    `}<g parentName="g" {...{
                    "id": "bounding-box"
                  }}>{`
                        `}<polygon parentName="g" {...{
                      "id": "shape",
                      "points": "0 0 24 0 24 24 0 24"
                    }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
                    "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
                    "id": "Combined-Shape",
                    "fill": "#444444",
                    "fillRule": "nonzero"
                  }}></path>{`
                `}</g>{`
              `}</svg></a>{`When you can live life to the fullest`}</h4>
          <p>{`When people are on their way, we can celebrate the benefits of good health and show people embracing this sense of liberation. It's emotive and dynamic, and should reflect a universal aspiration.`}</p>
          <ul>
            <li parentName="ul">{`People living life, empowered by the freedom of good health`}</li>
            <li parentName="ul">{`Everyday moments of joy that are relatable and desirable`}</li>
            <li parentName="ul">{`A sense of activeness through dynamic lighting and movements`}</li>
            <li parentName="ul">{`Expressing and sharing positivity with others`}</li>
            <li parentName="ul">{`Aspirational without exclusivity - energy through prevention`}</li>
            <li parentName="ul">{`Warm colours and natural lighting`}</li>
          </ul>
        </Stack>
      </Box>
      <p><img parentName="p" {...{
          "src": "/4c5f2222ce1de1a4840df720f45bc7ba/gettingYouOnYourWayCollage.png",
          "alt": "Collage of people smiling while being active; playing sport, swimming, stretching or walking the dog."
        }}></img></p>
    </div>
    <Box background="sunsetPink" padding={7} mdxType="Box">
      <Stack space={5} mdxType="Stack">
        <h3 {...{
          "id": "photography-donts",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#photography-donts",
            "aria-label": "photography donts permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "width": "16px",
              "height": "16px",
              "viewBox": "0 0 24 24",
              "version": "1.1",
              "xmlns": "http://www.w3.org/2000/svg",
              "xmlnsXLink": "http://www.w3.org/1999/xlink"
            }}>{`
                `}<g parentName="svg" {...{
                "id": "Icon/System/Hyperlink",
                "stroke": "none",
                "strokeWidth": "1",
                "fill": "none",
                "fillRule": "evenodd"
              }}>{`
                    `}<g parentName="g" {...{
                  "id": "bounding-box"
                }}>{`
                        `}<polygon parentName="g" {...{
                    "id": "shape",
                    "points": "0 0 24 0 24 24 0 24"
                  }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
                  "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
                  "id": "Combined-Shape",
                  "fill": "#444444",
                  "fillRule": "nonzero"
                }}></path>{`
                `}</g>{`
              `}</svg></a>{`Photography don'ts`}</h3>
        <p>{`To ensure photography aligns with our brand proposition, we've put together a few guidelines of what to avoid when selecting photographic imagery. Try to avoid:`}</p>
        <ul>
          <li parentName="ul">{`Photos shot from awkward angles that aren't flattering to the subjects`}</li>
          <li parentName="ul">{`Images shot from too far away, in a way that isn't intimate, or is - detached from the moment.`}</li>
          <li parentName="ul">{`Hard flash photography that washes out natural skin tones`}</li>
          <li parentName="ul">{`Clichéd scenarios of "wellness"`}</li>
          <li parentName="ul">{`Photos depicting activity or behaviour that could be seen as dangerous or risky for those involved`}</li>
          <li parentName="ul">{`Subjects that appear uncomfortable or distressed `}</li>
        </ul>
      </Stack>
    </Box>
    <h2 {...{
      "id": "illustrations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#illustrations",
        "aria-label": "illustrations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Illustrations`}</h2>
    <p>{`Our illustrations are an integral part of our asset library. They have been created to align with our brand proposition and act to visually represent "empowering prevention". As a design feature, illustrations bring aspirational and approachable personality to both our internal and external touchpoints, and can break up the visual flow of otherwise rigid pages and structures. Created by renowned Los Angeles-based illustrator `}<a parentName="p" {...{
        "href": "http://sebastiancuri.com"
      }}>{`Sebastian Curi`}</a>{`, our initial illustration library embodies that sense of personality through its depiction of intersectional traits, lifestyles, activities and relationships, providing a dynamic, personable and inclusive range of images to facilitate emotional connections. `}</p>
    <p>{`For ease of access when mocking up designs in Figma, we have included high resolution png variants as Components in our Foundation library. These are provided primarily as creative assets for ideation and exploration, but given their high resolution, should be suitable in most cases for creating `}<a parentName="p" {...{
        "href": "/foundations/imagery/#logos-graphics--illustrations"
      }}>{`exportable assets`}</a>{`.`}</p>
    <p>{`As per any imagery usage, approval must be sought from nib's `}<a parentName="p" {...{
        "href": "mailto:brandteam@nib.com.au"
      }}>{`brand team`}</a>{` before any assets are put into production.`}</p>
    <SecondaryButton href={FIGMA_LIBRARY_FOUNDATIONS_URL} mdxType="SecondaryButton">Figma foundations</SecondaryButton>
    <p><img parentName="p" {...{
        "src": "/65b55c4c866e5b6a39dd8d8ed1cda9c0/illustrationCollage.png",
        "alt": "Collage of illustrated characters"
      }}></img></p>
    <h3 {...{
      "id": "break-out-illustrations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#break-out-illustrations",
        "aria-label": "break out illustrations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Break-out illustrations`}</h3>
    <p>{`By utilising transparent (alpha channel) backgrounds, these illustrations provide a unique design opportunity to challenge rigid page structures. Illustrations are the only elements within Mesh that are permitted to 'break out' of their parent containers, and as such, should be used carefully to preserve their effectiveness as a 'moment of delight'. `}</p>
    <Box background="warmWhite" padding={7} mdxType="Box">
      <Columns space={{
        xs: 5,
        sm: 7
      }} collapseBelow="sm" mdxType="Columns">
      <Column width="content" mdxType="Column">
        <ApprovalGraphicIcon mdxType="ApprovalGraphicIcon" />
      </Column>
      <Column mdxType="Column">
          <Stack space={5} mdxType="Stack">
            <h4 {...{
              "id": "to-ensure-consistency-across-uses-consider-the-following-guidelines",
              "style": {
                "position": "relative"
              }
            }}><a parentName="h4" {...{
                "href": "#to-ensure-consistency-across-uses-consider-the-following-guidelines",
                "aria-label": "to ensure consistency across uses consider the following guidelines permalink",
                "className": "anchor before"
              }}><svg parentName="a" {...{
                  "width": "16px",
                  "height": "16px",
                  "viewBox": "0 0 24 24",
                  "version": "1.1",
                  "xmlns": "http://www.w3.org/2000/svg",
                  "xmlnsXLink": "http://www.w3.org/1999/xlink"
                }}>{`
                `}<g parentName="svg" {...{
                    "id": "Icon/System/Hyperlink",
                    "stroke": "none",
                    "strokeWidth": "1",
                    "fill": "none",
                    "fillRule": "evenodd"
                  }}>{`
                    `}<g parentName="g" {...{
                      "id": "bounding-box"
                    }}>{`
                        `}<polygon parentName="g" {...{
                        "id": "shape",
                        "points": "0 0 24 0 24 24 0 24"
                      }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
                      "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
                      "id": "Combined-Shape",
                      "fill": "#444444",
                      "fillRule": "nonzero"
                    }}></path>{`
                `}</g>{`
              `}</svg></a>{`To ensure consistency across uses, consider the following guidelines:`}</h4>
            <ul>
              <li parentName="ul">{`Colors should be bright, warm and complementary to our brand palette`}</li>
              <li parentName="ul">{`Subjects should typically be looking and/or moving towards a north, east or north-east direction (left to right, upwards)`}</li>
              <li parentName="ul">{`Consider `}<a parentName="li" {...{
                  "href": "/foundations/imagery/#diversity-and-inclusion"
                }}>{`diversity and inclusion`}</a>{` - ensuring usage of illustrations is inclusive and intersectional across pages, products, flows and experiences.`}</li>
              <li parentName="ul">{`When 'breaking out' of parent containers, ensure that responsive behaviour does not cause illustrations to obscure important content or interactive elements.`}</li>
            </ul>
          </Stack>
        </Column>
      </Columns>
    </Box>
    <h3 {...{
      "id": "graphic-frames--backgrounds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#graphic-frames--backgrounds",
        "aria-label": "graphic frames  backgrounds permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Graphic frames `}{`&`}{` backgrounds`}</h3>
    <p>{`nib's brand guidelines provide a facility for usage of a graphic arrow, derived from the company logo, as a background elementfor illustrations and as a photo frame, with specific guidelines around its usage.`}</p>
    <h4 {...{
      "id": "illustration-background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#illustration-background",
        "aria-label": "illustration background permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Illustration background`}</h4>
    <p>{`In this usage case, the arrow may be used a bold background graphic behind an illustration, and sits in the top right corner of its parent container, hinting at sunlight. To preserve the 'north-east' movement of our graphic and illustrated elements, the arrow should never be flipped or rotated. `}</p>
    <Tiles columns={{
      xs: 1,
      md: 2
    }} space={0} mdxType="Tiles">
      <p><img parentName="p" {...{
          "src": "/0021466cb95c70649d70267c9342eac9/GraphicFrameIllustration01.png",
          "alt": "Illustration on a sage green arrow background"
        }}></img></p>
      <p><img parentName="p" {...{
          "src": "/e76b11f56ec716213b68b2b646f583af/GraphicFrameIllustration02.png",
          "alt": "Illustration on a light pink arrow background"
        }}></img></p>
      <p><img parentName="p" {...{
          "src": "/8eef3ad0554dd031fd1d5f13e0236ed8/GraphicFrameIllustration03.png",
          "alt": "Illustration on a warm white arrow background"
        }}></img></p>
      <p><img parentName="p" {...{
          "src": "/f22fdf8d4db9d11a430e57f9358ab74c/GraphicFrameIllustration04.png",
          "alt": "Illustration on a dark green arrow background"
        }}></img></p>
    </Tiles>
    <h4 {...{
      "id": "arrow-photo-frame",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#arrow-photo-frame",
        "aria-label": "arrow photo frame permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Arrow photo frame`}</h4>
    <p>{`Our graphic arrow can also be used as a frame to stylise our use of photography. This frame provides another bold graphic element to accompany branded content, and imbues our photography with a sense of positive movement and optimism.`}</p>
    <Tiles columns={{
      xs: 1,
      md: 3
    }} space={0} mdxType="Tiles">
      <Box background="sageGreen" padding={7} mdxType="Box">
        <p><img parentName="p" {...{
            "src": "/b38f685c327c3d17e49ba21d247cfcb9/arrowFrame1.png",
            "alt": "Photo inside a sage green arrow frame"
          }}></img></p>
      </Box>
      <Box background="sunsetPink" padding={7} mdxType="Box">
        <p><img parentName="p" {...{
            "src": "/a5442e101cbce05cbc24a8bef4f9f46f/arrowFrame2.png",
            "alt": "Photo inside a light pink arrow frame"
          }}></img></p>
      </Box>
      <Box background="warmWhite" padding={7} mdxType="Box">
        <p><img parentName="p" {...{
            "src": "/40a644b0ac7695cad7e4bc8736f62727/arrowFrame3.png",
            "alt": "Photo inside a warm white arrow frame"
          }}></img></p>
      </Box>
    </Tiles>
    <p>{`When selecting imagery for use in this context, ensure that subjects (particularly faces) are contained within the key visual safe area, outlined below. Our `}<a parentName="p" {...{
        "href": ""
      }}>{`arrowFrame component`}</a>{` in Figma is based on a `}<inlineCode parentName="p">{`480px`}</inlineCode>{` square, overlaid with a `}<inlineCode parentName="p">{`30x30`}</inlineCode>{` grid and safe-area guide, and can be scaled dynamically. `}</p>
    <p><img parentName="p" {...{
        "src": "/50fe02fab057c6a1e70bf1a65ebf22df/arrowFrameExample.png",
        "alt": "Infographic to show how to use the arrow photo frame"
      }}></img></p>
    <Box background="sunsetPink" padding={7} mdxType="Box">
      <Stack space={5} mdxType="Stack">
        <h3 {...{
          "id": "arrow-photo-frame-donts",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#arrow-photo-frame-donts",
            "aria-label": "arrow photo frame donts permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "width": "16px",
              "height": "16px",
              "viewBox": "0 0 24 24",
              "version": "1.1",
              "xmlns": "http://www.w3.org/2000/svg",
              "xmlnsXLink": "http://www.w3.org/1999/xlink"
            }}>{`
                `}<g parentName="svg" {...{
                "id": "Icon/System/Hyperlink",
                "stroke": "none",
                "strokeWidth": "1",
                "fill": "none",
                "fillRule": "evenodd"
              }}>{`
                    `}<g parentName="g" {...{
                  "id": "bounding-box"
                }}>{`
                        `}<polygon parentName="g" {...{
                    "id": "shape",
                    "points": "0 0 24 0 24 24 0 24"
                  }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
                  "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
                  "id": "Combined-Shape",
                  "fill": "#444444",
                  "fillRule": "nonzero"
                }}></path>{`
                `}</g>{`
              `}</svg></a>{`Arrow photo frame don'ts`}</h3>
        <p>{`To ensure appropriate use of arrow frames, we've put together a few guidelines of what to avoid when selecting photographs. `}</p>
        <ul>
          <li parentName="ul">{`Peaks or edges should not cover or cut off the face of any subjects in the image`}</li>
          <li parentName="ul">{`Where possible, avoid having the peaks create unnecessary 'moments' within the frame (e.g. 'pointing' to particular elements, subjects or points on their body) `}</li>
          <li parentName="ul">{`While secondary or out-of-focus subjects may appear outside it, ensure that the primary visual focus is contained within the safe area.`}</li>
        </ul>
      </Stack>
    </Box>
    <h2 {...{
      "id": "contact",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#contact",
        "aria-label": "contact permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Contact`}</h2>
    <p>{`For further information and questions regarding the use of imagery please contact the nib Design Communications team via email at `}<a parentName="p" {...{
        "href": "mailto:designcommunications@nib.com.au"
      }}>{`designcommunications@nib.com.au`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      